import React from 'react'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { TEXT_STYLES } from 'TEXT_STYLES'

const StepsWrapper = styled.div`
  display: flex;
`
const StepColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const StepImage = styled.img`
  width: 100%;
  max-width: ${props => props.width};
  height: ${props => props.height};
  object-fit: contain;
  margin: ${props => props.stepImageMargin};
`
const StepDescription = styled.div`
  max-width: 165px;
`
const StepTitle = styled.h3`
  ${TEXT_STYLES.H3Black}
  margin: 5px 0 0 0;
`
const StepSubtitle = styled.p`
  ${TEXT_STYLES.BodyBlack}
  margin: 0;
`

const Separator = styled.img`
  max-width: 50px;
  width: 100%;
  height: ${props => props.seperatorOffset};
`

const MwsSyncSteps = props => {
  const { t, width, height, seperatorOffset, stepImageMargin } = props

  const marketplaceTitle = t(
    'mwsSync:IntroductionModal.marketplaceTitle',
    'Select Marketplace'
  )
  const marketplaceMessage = t(
    'mwsSync:IntroductionModal.marketplaceMessage',
    'Select your Region and Primary Marketplace.'
  )

  const accountTitle = t(
    'mwsSync:IntroductionModal.accountTitle',
    'Connect Account'
  )
  const accountMessage = t(
    'mwsSync:IntroductionModal.accountMessage',
    'Connect your Seller Central account information.'
  )

  const ppcSyncTitle = t(
    'mwsSync:IntroductionModal.ppcSyncTitle',
    'Connect PPC'
  )
  const ppcSyncMessage = t(
    'mwsSync:IntroductionModal.ppcSyncMessage',
    'Option to connect your PPC data.'
  )

  return (
    <StepsWrapper>
      <StepColumn>
        <StepImage
          width={width}
          height={height}
          src="//djga94vwgu7gt.cloudfront.net/assets/junglescout/ppc-sync-step-1@3x.png"
          stepImageMargin={stepImageMargin}
        />
        <StepDescription>
          <StepTitle>{marketplaceTitle}</StepTitle>
          <StepSubtitle>{marketplaceMessage}</StepSubtitle>
        </StepDescription>
      </StepColumn>

      <Separator
        src={require('../../icons/svg/mws-step-connector.svg')}
        alt="connect"
        seperatorOffset={seperatorOffset}
      />

      <StepColumn>
        <StepImage
          width={width}
          height={height}
          src="//djga94vwgu7gt.cloudfront.net/assets/junglescout/ppc-sync-step-2@3x.png"
          stepImageMargin={stepImageMargin}
        />
        <StepDescription>
          <StepTitle>{accountTitle}</StepTitle>
          <StepSubtitle>{accountMessage}</StepSubtitle>
        </StepDescription>
      </StepColumn>

      <Separator
        src={require('../../icons/svg/mws-step-connector.svg')}
        alt="connect"
        seperatorOffset={seperatorOffset}
      />
      <StepColumn>
        <StepImage
          width={width}
          height={height}
          src="//djga94vwgu7gt.cloudfront.net/assets/junglescout/ppc-sync-step-3@3x.png"
          stepImageMargin={stepImageMargin}
        />
        <StepDescription>
          <StepTitle>{ppcSyncTitle}</StepTitle>
          <StepSubtitle>{ppcSyncMessage}</StepSubtitle>
        </StepDescription>
      </StepColumn>
    </StepsWrapper>
  )
}

MwsSyncSteps.defaultProps = {
  width: '137px',
  height: '101px',
  seperatorOffset: '100px',
  stepImageMargin: '0'
}

MwsSyncSteps.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  seperatorOffset: PropTypes.string,
  stepImageMargin: PropTypes.string
}

const WrappedComponent = withTranslation(['mwsSync'])(MwsSyncSteps)
export { WrappedComponent as MwsSyncSteps }
