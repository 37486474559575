import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import cx from 'classnames'
import { COLORS } from 'COLORS'
import scoutingSVG from 'icons/svg/scouting.svg'

const EmptyStateWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
  margin-top: 75px;

  ${props =>
    !props.autoScaleVertically &&
    `
    min-height: 300px;
  `}

  ${props =>
    props.horizontal &&
    !props.horizontalWhite &&
    `
    margin: 50px;
    max-width: 800px;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: stretch;
  `}

  ${props =>
    props.horizontalWhite &&
    `
    flex-direction: row-reverse;
    justify-content: center;
    align-items: stretch;
    background-color: ${COLORS.white};
    margin: 0px;
    border-radius: 0px;
    border: 1px solid ${COLORS.grey100};
    border-top: 0px;
  `}

  ${props =>
    props.verticalWhite &&
    `
    background-color: ${COLORS.white};
    border: 1px solid ${COLORS.grey100};
    padding-top: 48px;
    padding-bottom: 42px;
  `}
`

const EmptyStateColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${props => (props.horizontal ? 'flex-start' : 'center')};
  ${props => props.verticalCenter && 'justify-content: center'}
  ${props => props.margin && `margin: ${props.margin};`}
`

const EmptyStateImage = styled.img`
  width: 100%;
  max-width: ${props => (props.imageWidth ? `${props.imageWidth}` : '225px')};
  height: ${props =>
    props.imageHeight ? `${props.imageHeight}` : 'max-content'};
`

const EmptyStateTitle = styled.div`
  font-family: 'Inter', sans-serif;
  font-size: ${props => (props.smallerFonts ? 18 : 36)}px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: -0.5px;
  color: ${COLORS.grey700};
  text-align: center;
  font-weight: 300;
  margin: ${props => (props.margin ? props.margin : '20px 0 -10px')};
  ${props => props.titleWidth && `width: ${props.titleWidth};`}
  ${props => props.horizontal && `text-align: left;`}
`

const EmptyStateMessage = styled.div`
  font-family: 'Inter', sans-serif;
  font-size: ${props => (props.smallerFonts ? 14 : 16)}px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${COLORS.grey700};
  text-align: center;
  font-weight: normal;
  margin: ${props => (props.margin ? props.margin : '30px 0')};
  max-width: ${props => (props.wide ? '500px' : '400px')};
  .empty-state__buttons {
    margin-top: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      flex-basis: 150px;
    }
  }

  ${props => props.horizontal && `text-align: left;`}
`

const EmptyStateBulletPoint = styled.li`
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${COLORS.grey700};
  text-align: center;
  font-weight: normal;
  margin: 8px 0;
  max-width: ${props => (props.wide ? '500px' : '400px')};

  ${props => props.horizontal && `text-align: left;`}
`

const renderImage = props => {
  const { icon, imageUrl, message, imageWidth, imageHeight } = props

  if (icon) return icon
  if (imageUrl === 'none') return null

  const imagePath = imageUrl || scoutingSVG

  return (
    <EmptyStateImage
      src={imagePath}
      alt={message}
      imageWidth={imageWidth}
      imageHeight={imageHeight}
    />
  )
}

const EmptyState = props => {
  const {
    wrapperClassName,
    className,
    horizontal,
    autoScaleVertically,
    header,
    message,
    callToAction,
    wide,
    titleWidth,
    horizontalWhite,
    verticalWhite,
    imageMargin,
    textMargin,
    smallerFonts,
    verticalCenter,
    bulletPoints,
    messageMargin,
    titleMargin
  } = props

  return (
    <EmptyStateWrapper
      className={cx(wrapperClassName, className)}
      horizontal={horizontal}
      autoScaleVertically={autoScaleVertically}
      verticalWhite={verticalWhite}
      horizontalWhite={horizontalWhite}>
      <EmptyStateColumn horizontal={horizontal} margin={imageMargin}>
        {renderImage(props)}
      </EmptyStateColumn>
      <EmptyStateColumn
        horizontal={horizontal}
        horizontalWhite={horizontalWhite}
        margin={textMargin}
        verticalCenter={verticalCenter}>
        {!!header && (
          <EmptyStateTitle
            horizontal={horizontal}
            titleWidth={titleWidth}
            margin={titleMargin}
            smallerFonts={smallerFonts}>
            {header}
          </EmptyStateTitle>
        )}
        <EmptyStateMessage
          horizontal={horizontal}
          wide={wide}
          margin={messageMargin}
          smallerFonts={smallerFonts}>
          {message}
        </EmptyStateMessage>
        {bulletPoints.length >= 1 && (
          <ul style={{ marginTop: '16px' }}>
            {bulletPoints.map((point, idx) => {
              return (
                <EmptyStateBulletPoint
                  // eslint-disable-next-line react/no-array-index-key
                  key={idx}
                  horizontal={horizontal}
                  wide={wide}
                  smallerFonts={smallerFonts}>
                  {point}
                </EmptyStateBulletPoint>
              )
            })}
          </ul>
        )}
        {callToAction}
      </EmptyStateColumn>
    </EmptyStateWrapper>
  )
}

EmptyState.defaultProps = {
  autoScaleVertically: false,
  horizontal: false,
  smallerFonts: false,
  verticalCenter: false,
  bulletPoints: []
}

EmptyState.propTypes = {
  /* eslint-disable react/no-unused-prop-types */
  autoScaleVertically: PropTypes.bool,
  callToAction: PropTypes.any,
  className: PropTypes.string,
  header: PropTypes.any,
  horizontal: PropTypes.bool,
  horizontalWhite: PropTypes.bool,
  icon: PropTypes.any,
  imageHeight: PropTypes.string,
  imageMargin: PropTypes.string,
  imageUrl: PropTypes.string,
  imageWidth: PropTypes.string,
  message: PropTypes.any.isRequired,
  smallerFonts: PropTypes.bool,
  textMargin: PropTypes.string,
  titleWidth: PropTypes.string,
  verticalWhite: PropTypes.bool,
  wide: PropTypes.bool,
  wrapperClassName: PropTypes.string,
  verticalCenter: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  bulletPoints: PropTypes.array,
  messageMargin: PropTypes.string,
  titleMargin: PropTypes.string
}

export { EmptyState }
