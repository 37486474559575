import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Button, ButtonType } from '@junglescout/edna'

import { TEXT_STYLES } from 'TEXT_STYLES'

import { COBALT_APP_TYPE } from 'constants/themes'
import {
  ACCESS_LIMIT_TYPE,
  LISTINGS_SCOPE,
  TOTAL_SEARCHES_SCOPE,
  MONTHLY_SEARCHES_SCOPE,
  PRODUCT_TRACKS_SCOPE,
  QUANTITY_LIMIT_TYPE,
  RANK_TRACKER_KEYWORDS_SCOPE,
  SUPPLIER_GROUPS_SCOPE,
  SUPPLIER_TRACKS_SCOPE,
  TIMEFRAME_LIMIT_TYPE,
  REVIEW_AUTOMATION_SCOPE
} from 'constants/account/permissions'
import { JSPRO_BUCKET } from 'constants/s3'

import { getFeatureLimitModalCopy } from 'helpers/permissions'
import { sendCtaClickEvent, sendUpgradeModalEvent } from 'helpers/segment'
import { useUpgradeURL } from 'hooks/upgrade_limit_modal'

import { Modal } from 'ui_elements/Modal/Modal'

const StyledImage = styled.img`
  width: 85%;
  margin-bottom: 40px;
`
const ModalContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
`
const UpgradeTitle = styled.div`
  ${TEXT_STYLES.Coaching1}
`
const Description = styled.div`
  font-weight: 500 !important;
  max-width: 350px;
  ${TEXT_STYLES.Coaching2Bold}
  ${props => (props.noBottomMargin ? '' : 'margin-bottom: 40px;')}
`

export const FeatureLimitModal = ({ toggleModal, limitType, scope }) => {
  const { t } = useTranslation('account')
  const {
    user: { is_account_owner, is_account_manager },
    appType,
    membershipInfo: { hasActiveMembership }
  } = useSelector(state => state.globalData)

  const { openURL, url } = useUpgradeURL()

  useEffect(() => {
    sendUpgradeModalEvent({})
  }, [])

  if (appType === COBALT_APP_TYPE) return null

  const { header, body } = getFeatureLimitModalCopy(limitType, t, scope)

  const showUpgradeCTAs =
    is_account_owner || is_account_manager || !hasActiveMembership

  const renderPrimaryButton = () => {
    const handlePrimaryClick = () => {
      sendCtaClickEvent({
        text: 'Explore New Plan',
        location: 'Upsell Modal',
        destination: url
      })

      toggleModal()
      openURL()
    }

    return (
      <Button
        btnType={ButtonType.PRIMARY}
        margin="0 0 18px"
        onClick={handlePrimaryClick}>
        {t('account:FeatureLimitModal.buttons.upgrade', 'Explore new plans')}
      </Button>
    )
  }

  const renderSecondaryButton = () => {
    const [buttonLabel, onClickAction] = [
      t('account:FeatureLimitModal.buttons.keepPlan', 'Keep my plan'),
      toggleModal
    ]

    const handleSecondaryClick = () => {
      sendCtaClickEvent({
        text: 'Keep My Plan',
        location: 'Upsell Modal',
        destination: 'modal'
      })
      onClickAction()
    }

    return (
      <Button btnType={ButtonType.PRIMARY_TEXT} onClick={handleSecondaryClick}>
        {buttonLabel}
      </Button>
    )
  }

  return (
    <Modal handleBackdropClick={toggleModal} isVisible>
      <ModalContent>
        <StyledImage src={`${JSPRO_BUCKET}/images/upsell/feature-limits.svg`} />
        <UpgradeTitle>{header}</UpgradeTitle>
        <Description noBottomMargin={!showUpgradeCTAs}>{body}</Description>
        {showUpgradeCTAs ? (
          <>
            {renderPrimaryButton()}
            {renderSecondaryButton()}
          </>
        ) : (
          ''
        )}
      </ModalContent>
    </Modal>
  )
}

FeatureLimitModal.defaultProps = {
  scope: undefined
}

FeatureLimitModal.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  limitType: PropTypes.oneOf([
    TIMEFRAME_LIMIT_TYPE,
    QUANTITY_LIMIT_TYPE,
    ACCESS_LIMIT_TYPE
  ]).isRequired,
  scope: PropTypes.oneOf([
    TOTAL_SEARCHES_SCOPE,
    MONTHLY_SEARCHES_SCOPE,
    LISTINGS_SCOPE,
    PRODUCT_TRACKS_SCOPE,
    SUPPLIER_TRACKS_SCOPE,
    SUPPLIER_GROUPS_SCOPE,
    RANK_TRACKER_KEYWORDS_SCOPE,
    REVIEW_AUTOMATION_SCOPE
  ])
}
