import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import './modal.sass'
import { CloseIcon } from 'icons/CloseIcon/CloseIcon'
import { Dialog } from 'ui_elements/Dialog/Dialog'

class Modal extends Component {
  componentDidUpdate(prevProps) {
    if (
      prevProps.isVisible !== this.props.isVisible &&
      !!this.props.isVisible &&
      this.props.handleOnClose
    ) {
      this.props.handleOnClose()
    }
  }

  handleBackdropClick = () => {
    if (this.props.isVisible && this.props.handleBackdropClick)
      this.props.handleBackdropClick()
  }

  handleCloseButtonClick = () => {
    if (this.props.handleCloseButtonClick) {
      this.props.handleCloseButtonClick()
    } else {
      this.props.handleBackdropClick()
    }
  }

  componentWillUnmount() {
    if (this.props.handleOnClose) this.props.handleOnClose()
  }

  render() {
    const {
      modalClasses,
      headerClasses,
      containerClasses,
      contentClasses,
      isVisible,
      backdropClasses,
      ariaLabel,
      ariaLabelledby,
      dialog,
      backgroundColor
    } = this.props
    const modalClassList = cx(
      'modal',
      { hidden: !isVisible },
      modalClasses,
      this.props.className
    )
    const { title } = this.props
    const headerClassList = cx('modal-header', headerClasses)
    const contentClassList = cx('modal-content', contentClasses)
    const containerClassList = cx('modal-container', containerClasses)
    const backdropClassList = cx('modal-backdrop', backdropClasses)
    const emptyHeaderClassList = cx(
      'modal-header empty-modal-header',
      headerClasses
    )

    const ModalContent = (
      <div className={modalClassList}>
        <div className={backdropClassList} onClick={this.handleBackdropClick} />
        <div
          className={containerClassList}
          role="dialog"
          aria-label={ariaLabel}
          aria-labelledby={ariaLabelledby}>
          {title ? (
            <div className={headerClassList}>
              <label>{title}</label>
              <CloseIcon
                onClick={this.handleCloseButtonClick}
                className="modal-close-icon"
                color="grey900"
                size={16}
              />
            </div>
          ) : (
            <div className={emptyHeaderClassList}>
              <CloseIcon
                onClick={this.handleCloseButtonClick}
                className="modal-close-icon"
                color="grey900"
                size={16}
              />
            </div>
          )}
          <div className={contentClassList}>{this.props.children}</div>
        </div>
      </div>
    )

    return dialog ? (
      <Dialog isOpen={isVisible} backgroundColor={backgroundColor}>
        {ModalContent}
      </Dialog>
    ) : (
      ModalContent
    )
  }
}

Modal.defaultProps = {
  ariaLabel: undefined,
  ariaLabelledby: undefined,
  dialog: false,
  backgroundColor: 'white'
}

Modal.propTypes = {
  ariaLabel: PropTypes.string,
  ariaLabelledby: PropTypes.string,
  isVisible: PropTypes.bool.isRequired,
  dialog: PropTypes.bool,
  containerClasses: PropTypes.string,
  headerClasses: PropTypes.string,
  contentClasses: PropTypes.string,
  title: PropTypes.any,
  handleOnClose: PropTypes.func,
  handleBackdropClick: PropTypes.func,
  handleCloseButtonClick: PropTypes.func,
  backgroundColor: PropTypes.string
}

export { Modal }
