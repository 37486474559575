import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { COLORS } from 'COLORS'
import { TEXT_STYLES } from 'TEXT_STYLES'

const Group = styled.div`
  margin-bottom: 20px;

  & > label {
    ${TEXT_STYLES.H4Black}
    display: block;
    color: ${props => (props.error ? COLORS.red500 : COLORS.grey900)};
    margin-bottom: 5px;
    max-height: 17px;
  }

  & > input {
    border: 1px solid ${props => (props.error ? COLORS.red500 : COLORS.grey100)};
    width: 100%;
    min-height: 35px;
    padding-left: 10px;
  }
`

const Error = styled.span`
  ${TEXT_STYLES.BodyRedError}
  display: flex;
  flex-direction: row;
  align-items: center;
  ${props => props.showErrorIcon && `margin-top: 10px;`}
`

const ErrorIcon = styled.img`
  margin-right: 10px;
`

class FieldGroup extends Component {
  render() {
    const { children, error, showErrorIcon } = this.props
    return (
      <Group {...this.props}>
        {children}
        {error ? (
          <Error showErrorIcon={showErrorIcon}>
            {showErrorIcon && (
              <ErrorIcon
                src={require('../../icons/svg/error-balloon.svg')}
                alt="Error"
              />
            )}
            {error}
          </Error>
        ) : null}
      </Group>
    )
  }
}

FieldGroup.defaultProps = {
  showErrorIcon: false
}

FieldGroup.propTypes = {
  children: PropTypes.any.isRequired,
  error: PropTypes.string,
  showErrorIcon: PropTypes.bool
}

export { FieldGroup }
