import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { CollapseRowIcon } from 'icons/CollapseRowIcon/CollapseRowIcon'
import { TEXT_STYLES } from 'TEXT_STYLES'
import { CollapseIconV1 as CollapseIcon } from 'icons/CollapseIcon/CollapseIcon'

const ExpandableSectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  :hover {
    cursor: pointer;
  }
`

const ExpandableSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const CustomIcon = styled(CollapseRowIcon)`
  margin-right: 5px;
`

const TitleSection = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  ${TEXT_STYLES.H4Black}
`

const ExpandableSection = props => {
  const {
    children,
    className,
    dataId,
    expandIconType,
    title,
    defaultState,
    extra,
    renderCustomIcon,
    callback,
    expandIconPosition
  } = props
  const [expanded, toggleExpandedSection] = useState(defaultState)

  const renderIcon = () => {
    switch (expandIconType) {
      case 'expand':
        return <CustomIcon isExpanded={expanded} />
      case 'custom':
        return renderCustomIcon(expanded)
      default:
        return (
          <CollapseIcon
            direction={expanded ? 'up' : 'down'}
            className="collapse-icon"
            height="10px"
            width="15px"
            viewBox="0 0 20 21"
          />
        )
    }
  }

  const renderTitleSection = () => {
    return expandIconPosition === 'left' ? (
      <TitleSection>
        {renderIcon()}
        {title}
      </TitleSection>
    ) : (
      <TitleSection>
        {title}
        {renderIcon()}
      </TitleSection>
    )
  }

  return (
    <ExpandableSectionWrapper>
      <ExpandableSectionHeader
        onClick={() => {
          callback(!expanded)
          toggleExpandedSection(!expanded)
        }}
        className={className}
        data-id={dataId}>
        {renderTitleSection()}
        {extra || ''}
      </ExpandableSectionHeader>
      {expanded && <div>{children}</div>}
    </ExpandableSectionWrapper>
  )
}

ExpandableSection.defaultProps = {
  className: '',
  dataId: undefined,
  extra: undefined,
  expandIconPosition: 'left',
  defaultState: false,
  renderCustomIcon: () => {},
  callback: () => {},
  expandIconType: 'expand'
}

ExpandableSection.propTypes = {
  title: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  dataId: PropTypes.string,
  defaultState: PropTypes.bool,
  extra: PropTypes.node,
  expandIconPosition: PropTypes.oneOf(['left', 'right']),
  renderCustomIcon: PropTypes.func,
  callback: PropTypes.func,
  expandIconType: PropTypes.oneOf(['expand', 'collapse', 'custom'])
}

export { ExpandableSection }
