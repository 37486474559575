import React, { Component, isValidElement } from 'react'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import includes from 'lodash/includes'
import map from 'lodash/map'
import onClickOutside from 'react-onclickoutside'
import cx from 'classnames'
import EmptySearchList from 'icons/svg/empty-search-list.svg'
import './multiselect.sass'
import styled from 'styled-components'

import { LoadingIcon } from '@junglescout/edna'
import { TextInput } from 'ui_elements/TextInput/TextInput'
import { EmptyState } from 'ui_elements/EmptyState/EmptyState'
import { MultiSelectElement } from './MultiSelectElement'

const GroupHeader = styled.div`
  margin: 8px 0 8px 10px;
`
const StyledEmptyState = styled(EmptyState)`
  margin-top: 0px;
  [class*='EmptyStateTitle'] {
    font-size: 26px;
    margin: 5px 0px 0px;
  }
  [class*='EmptyStateMessage'] {
    font-size: 12px;
    margin: 5px 0px 0px;
  }
`
const TextInputContainer = styled.div`
  width: 100%;
  display: flex;
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

class MultiSelectComponent extends Component {
  state = {
    editingElement: {}
  }

  resetStateToDefault = () => {
    this.setState({
      editingElement: {}
    })
  }

  toggleList = () => {
    const { handleToggle, disabled } = this.props
    if (handleToggle && !disabled) {
      handleToggle()
    }
  }

  handleClickOutside = () => {
    const { handleOutsideClick, isComponentOpen } = this.props
    if (handleOutsideClick && isComponentOpen) {
      handleOutsideClick()
      this.resetStateToDefault()
    }
  }

  setEditingElement = element => {
    this.setState({
      editingElement: element
    })
  }

  renderElement = (element, i) => {
    const {
      handleUncheck,
      handleToggle,
      forceSingleSelection,
      nameProp,
      valueProp,
      hideSelector,
      selectedElements,
      editElementAction,
      nameFormatter,
      handleClearSelection
    } = this.props
    const { editingElement } = this.state
    const selectedValues = includes(
      ['string', 'boolean', 'number'],
      typeof selectedElements[0]
    )
      ? selectedElements
      : map(selectedElements, valueProp)
    const elementIsSelected = includes(selectedValues, element[valueProp])

    const isClearSelectionPresent = !!element.clearSelectionId

    const handleSelection = element => {
      if (elementIsSelected && handleUncheck) {
        handleUncheck(element)
      } else if (isClearSelectionPresent) {
        handleClearSelection()
      } else {
        this.props.handleSelection(element)
        if (forceSingleSelection) {
          handleToggle()
        }
      }
    }
    if (element.group_start) {
      return (
        <React.Fragment key={i}>
          <GroupHeader>
            <b>{element.group_start}</b>
          </GroupHeader>
          <MultiSelectElement
            element={element}
            handleSelection={handleSelection}
            isSelected={elementIsSelected}
            noElementsSelected={!selectedElements.length}
            nameProp={nameProp}
            valueProp={valueProp}
            forceSingleSelection={forceSingleSelection}
            hideSelector={hideSelector}
            nameFormatter={nameFormatter}
            editElementAction={editElementAction}
            setEditingElement={this.setEditingElement}
            editingElement={editingElement}
            isClearSelectionPresent={isClearSelectionPresent}
          />
        </React.Fragment>
      )
    }

    return (
      <MultiSelectElement
        key={i}
        element={element}
        handleSelection={handleSelection}
        isSelected={elementIsSelected}
        noElementsSelected={!selectedElements.length}
        nameProp={nameProp}
        valueProp={valueProp}
        forceSingleSelection={forceSingleSelection}
        hideSelector={hideSelector}
        nameFormatter={nameFormatter}
        editElementAction={editElementAction}
        setEditingElement={this.setEditingElement}
        editingElement={editingElement}
        isClearSelectionPresent={isClearSelectionPresent}
      />
    )
  }

  renderSelectAll = () => {
    const {
      t,
      disableSelectAll,
      selectedElements = [],
      elements = [],
      handleToggleSelectAll,
      hideSelector,
      forceSingleSelection
    } = this.props

    if (!disableSelectAll) {
      const selectAllLabel = t('generic:SelectAll', 'Select All')
      const deselectAllLabel = t('generic:DeselectAll', 'Deselect All')
      const isEverythingSelected = selectedElements.length === elements.length
      return (
        <MultiSelectElement
          key={-1}
          element={{
            name: isEverythingSelected ? deselectAllLabel : selectAllLabel,
            command: isEverythingSelected ? 'Deselect All' : 'Select All'
          }}
          handleSelection={handleToggleSelectAll}
          isSelected={isEverythingSelected}
          hideSelector={hideSelector}
          forceSingleSelection={forceSingleSelection}
        />
      )
    }
    return ''
  }

  renderSearchBox() {
    const {
      searchable,
      onSearchChange,
      searchValue,
      searchPlaceholder
    } = this.props
    if (!searchable) {
      return null
    }

    return (
      <TextInputContainer>
        <TextInput
          placeholder={searchPlaceholder || 'Search'}
          onChange={onSearchChange}
          className="search-box"
          value={searchValue}
        />
      </TextInputContainer>
    )
  }

  renderTitle = () => {
    const {
      t,
      forceSingleSelection,
      elements = [],
      selectedElements = [],
      title
    } = this.props

    // If it's a React element just return it
    if (isValidElement(title)) {
      return title
    }

    if (forceSingleSelection || typeof title === 'string') return title
    // one is the singular version, other is the pluralized version
    // {one: Column Selected, other: Columns Selected}
    // these values are already translated and pluralized when passed in
    const { one, other } = title
    const elementCount = selectedElements.length

    if (elementCount === 0) {
      // {No} {Columns Selected}
      return `${t('generic:None', 'None')} ${other}`
    }
    if (elementCount === elements.length) {
      // {All} {Columns Selected}
      return `${t('generic:All', 'All')} ${other}`
    }
    if (elementCount === 1) {
      // 1 {Column Selected}
      return `1 ${one}`
    }
    // Example: {5} {Columns Selected}
    return `${elementCount} ${other}`
  }

  renderMultiSelect = (elements, maxHeight) => {
    const {
      dataId,
      className,
      isComponentOpen,
      error,
      selectedElements = [],
      loadNext,
      hasMore,
      isLoading,
      topComponent,
      bottomComponent,
      disabled,
      testId,
      multiSelectRef, // TODO: The component should use React.forwardRef instead but it would be a breaking change
      t,
      showEmptyState
    } = this.props
    const { editingElement } = this.state

    const mainCss = cx('multiselect no-select', className, {
      'multiselect--open': isComponentOpen,
      disabled,
      error
    })
    const dropdownClass = cx('dropdown', {
      'no-show': !isComponentOpen
    })
    const arrowCss = cx(
      'arrow',
      { 'arrow-up': isComponentOpen },
      { 'arrow-down': !isComponentOpen }
    )
    const titleCss = cx('title', {
      selected: isComponentOpen,
      populated: selectedElements.length > 0
    })

    const optionsContainerClass = `options-container ${
      isLoading ? 'is-loading' : ''
    }`

    const isListEditing = Object.entries(editingElement).length === 0

    return (
      <div className={mainCss} ref={multiSelectRef}>
        <div
          className="trigger"
          role="button" // TODO: the element should be a button instead
          data-id={dataId}
          data-testid={`${testId}-trigger`}
          onClick={() => this.toggleList()}>
          <TitleContainer className={titleCss}>
            {this.renderTitle()}
            <div className="actions">
              <span className={arrowCss} />
            </div>
          </TitleContainer>
        </div>
        <div
          data-testid={`${testId}-dropdown`}
          style={maxHeight ? { maxHeight, height: maxHeight } : {}}
          className={dropdownClass}>
          {this.renderSearchBox()}
          {showEmptyState &&
            !isLoading &&
            elements.length === 0 &&
            isComponentOpen && (
              <StyledEmptyState
                imageUrl={EmptySearchList}
                imageWidth="230px"
                header={t(
                  'generic:EmptyState.oopsNothingFound',
                  'Oops! Nothing found.'
                )}
                message={t(
                  'generic:EmptyState.pleaseChangeSearchCriteria',
                  'Please change your search criteria and try again.'
                )}
              />
            )}
          {!isLoading ? (
            <div
              className={optionsContainerClass}
              style={maxHeight ? { maxHeight, height: maxHeight } : {}}
              onScroll={({ target }) => {
                if (
                  hasMore &&
                  target.scrollTop + target.clientHeight >= target.scrollHeight
                ) {
                  loadNext()
                  // not using the react infinite scroll component here, it's way too fiddly/flakey etc
                }
              }}>
              {topComponent
                ? React.cloneElement(topComponent, {
                    isLoading,
                    disabled: !isListEditing
                  })
                : null}
              <ul className="options" role="listbox">
                {this.renderSelectAll()}
                {elements.map((element, i) => {
                  return this.renderElement(element, i)
                })}
              </ul>
              {!isLoading && bottomComponent
                ? React.cloneElement(bottomComponent, {
                    disabled: !isListEditing
                  })
                : null}
            </div>
          ) : null}
          {isLoading && (
            <div className="loading-container">
              <LoadingIcon color="grey900" size="15px" />
            </div>
          )}
        </div>
      </div>
    )
  }

  render() {
    const { elements, maxHeight } = this.props
    return this.renderMultiSelect(elements, maxHeight)
  }
}

MultiSelectComponent.defaultProps = {
  elements: [],
  selectedElements: [],
  handleClearSelection: PropTypes.func,
  nameProp: 'name',
  valueProp: 'value',
  testId: 'multi-select',
  maxHeight: null,
  searchPlaceholder: '',
  showEmptyState: false
}

MultiSelectComponent.propTypes = {
  dataId: PropTypes.string,
  elements: PropTypes.array.isRequired,
  selectedElements: PropTypes.array.isRequired,
  handleSelection: PropTypes.func.isRequired,
  handleUncheck: PropTypes.func,
  handleOutsideClick: PropTypes.func,
  handleToggle: PropTypes.func,
  handleClearSelection: PropTypes.func,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.element
  ]).isRequired,
  isComponentOpen: PropTypes.bool.isRequired,
  handleToggleSelectAll: PropTypes.func,
  nameFormatter: PropTypes.func,
  disableSelectAll: PropTypes.bool,
  forceSingleSelection: PropTypes.bool,
  className: PropTypes.string,
  hideSelector: PropTypes.bool, // hides the checkbox/radio
  // Use this when the object you want to display does not uses the default name/value attributes
  nameProp: PropTypes.string,
  valueProp: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  testId: PropTypes.string,

  // search options
  searchable: PropTypes.bool,
  onSearchChange: PropTypes.func,
  searchValue: PropTypes.string,
  isLoading: PropTypes.bool,
  topComponent: PropTypes.node,
  bottomComponent: PropTypes.element,
  multiSelectRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]),
  editElementAction: PropTypes.func,
  t: PropTypes.func.isRequired,

  // styling
  maxHeight: PropTypes.number,
  searchPlaceholder: PropTypes.string,
  showEmptyState: PropTypes.bool
}

export const MultiSelect = withTranslation('generic')(
  onClickOutside(MultiSelectComponent)
)
