import React, { useState } from 'react'
import styled, { useTheme } from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'

import { stopImpersonatingUser } from 'actions/impersonation'
import { Icon } from '@junglescout/edna'

const Bar = styled.div`
  position: relative;
  display: flex;
  background-color: ${props => props.theme.colors.red500};
  color: white;
  font-size: 15px;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  height: 25px;
  cursor: pointer;
  z-index: 7302;
  flex-basis: 100%;
  margin-bottom: -5px;
`

const Title = styled.div`
  display: flex;
  flex: 20;
  justify-content: center;
`

const CloseIconWrapper = styled.div`
  display: flex;
  flex: 1;
`

export const ImpersonationBar = () => {
  const dispatch = useDispatch()
  const { impersonatingUser } = useSelector(state => state.globalData)
  const [isStopping, setIsStopping] = useState(false)
  const { colors } = useTheme()

  const handleClick = async () => {
    if (!isStopping) {
      setIsStopping(true)
      await dispatch(stopImpersonatingUser())
      setIsStopping(false)
    }
  }

  return (
    <Bar onClick={handleClick}>
      <Title>IMPERSONATING {impersonatingUser}</Title>
      <CloseIconWrapper>
        <Icon name="X_CLOSE" color={colors.white} />
      </CloseIconWrapper>
    </Bar>
  )
}
