import React, { Component } from 'react'
import cx from 'classnames'

import { EditIcon } from 'icons/EditIcon/EditIcon'
import { CheckBoxIcon } from 'icons/CheckBoxIcon/CheckBoxIcon'
import { TextInput } from 'ui_elements/TextInput/TextInput'
import { PropTypes } from 'prop-types'
import styled from 'styled-components'
import { TEXT_STYLES } from 'TEXT_STYLES'

const EditMulitSelectItem = styled.div`
  display: flex;
  width: 95%;
`

const StyledTextInput = styled(TextInput)`
  font-size: 12px;
  margin: 8px 0 8px 10px;
  width: 100%;
`

const EditElement = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;
`

const MultiSelectListItem = styled.li`
  position: relative;
  &:hover {
    background-color: ${props => props.theme.colors.grey050};
  }
`

const EditButton = styled.div`
  width: 25px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  ${MultiSelectListItem}:hover & {
    visibility: initial;
  }
`

const ClearSelection = styled.div`
  ${TEXT_STYLES.H4Black};
  color: ${props => props.theme.colors.primary};
  font-size: 13px;
`

class MultiSelectElement extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isRowEditing: false,
      elementName: props.element.name
    }
  }

  selectElement = () => {
    if (!this.props.element.isHeader) {
      this.props.handleSelection(this.props.element)
    }
  }

  renderCheckboxOrRadio = () => {
    if (!this.props.hideSelector) {
      if (this.props.forceSingleSelection) {
        const css = cx('selection', { selected: this.props.isSelected })
        return (
          <div className="radio">
            <div className={css} />
          </div>
        )
      }
      if (this.props.isClearSelectionPresent) {
        return <div className="text" />
      }
      return (
        <CheckBoxIcon
          title={this.getCheckboxTitle()}
          style={{ minWidth: '15px' }}
          checked={this.props.isSelected}
        />
      )
    }
  }

  getCheckboxTitle = () => {
    const { element, nameProp } = this.props
    const titleProp = nameProp || 'name'
    return typeof element[titleProp] === 'string' ? element[titleProp] : ''
  }

  handleSubmitCategory = e => {
    const { setEditingElement, editElementAction, element } = this.props
    const { elementName } = this.state
    if (e.key === 'Enter') {
      editElementAction(element.id, elementName)
      setEditingElement({})
    }
  }

  renderName(isClearSelectionPresent, nameFormatter, element, nameProperty) {
    const { elementName } = this.state
    const { editingElement } = this.props
    const isElementEditing =
      Object.entries(editingElement).length !== 0 &&
      editingElement.id === element.id
    if (isElementEditing) {
      return (
        <EditMulitSelectItem
          onClick={e => {
            e.stopPropagation()
            e.preventDefault()
          }}>
          <StyledTextInput
            type="text"
            onChange={e =>
              this.setState({
                elementName: e.target.value
              })
            }
            onKeyPress={e => this.handleSubmitCategory(e)}
            value={elementName}
            autoFocus
            small
          />
        </EditMulitSelectItem>
      )
    }
    if (nameFormatter) {
      return nameFormatter(element)
    }

    if (isClearSelectionPresent) {
      return <ClearSelection>{element[nameProperty]}</ClearSelection>
    }

    if (element.isHeader) {
      return <div style={{ fontWeight: 600 }}>{element[nameProperty]}</div>
    }

    return <div className="display-name">{element[nameProperty]}</div>
  }

  renderEditButton = () => {
    const { element, setEditingElement, editingElement } = this.props
    const isRowEditing = editingElement.id === element.id
    return !isRowEditing ? (
      <EditButton>
        <EditIcon
          onClick={e => {
            // Stop the selecting on an element, cancel parent onClick
            e.stopPropagation()
            e.preventDefault()
            setEditingElement(element)
          }}
        />
      </EditButton>
    ) : null
  }

  renderEditSection = () => {
    const { element, editElementAction, editingElement } = this.props
    const isElementEditing =
      Object.entries(editingElement).length !== 0 &&
      editingElement.id === element.id
    return editElementAction && !isElementEditing ? (
      <EditElement>{this.renderEditButton()}</EditElement>
    ) : null
  }

  render() {
    const {
      element,
      customCss,
      nameProp,
      isSelected,
      noElementsSelected
    } = this.props

    if (element.clearSelectionId && noElementsSelected) {
      element.disabled = true
    } else if (element.clearSelectionId) {
      element.disabled = false
    }

    const nameProperty = nameProp || 'name'
    let optionCss = cx('option', customCss, {
      selected: isSelected
    })
    if (element.disabled) {
      optionCss = cx('inactive', optionCss)
    }
    const { isRowEditing } = this.state
    const { isClearSelectionPresent } = this.props
    return (
      <MultiSelectListItem
        role="option"
        className={optionCss}
        onClick={() =>
          !isRowEditing && !element.disabled ? this.selectElement() : () => {}
        }
        data-id={element.dataId}
        data-testid={element.dataId}>
        {!element.isHeader && this.renderCheckboxOrRadio()}
        {this.renderName(
          isClearSelectionPresent,
          this.props.nameFormatter,
          element,
          nameProperty
        )}
        {this.renderEditSection()}
      </MultiSelectListItem>
    )
  }
}

MultiSelectElement.propTypes = {
  nameFormatter: PropTypes.func,
  isSelected: PropTypes.bool,
  nameProp: PropTypes.string,
  element: PropTypes.object,
  customCss: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  hideSelector: PropTypes.bool,
  forceSingleSelection: PropTypes.bool,
  editElementAction: PropTypes.func,
  setEditingElement: PropTypes.func,
  editingElement: PropTypes.object,
  noElementsSelected: PropTypes.bool,
  isClearSelectionPresent: PropTypes.oneOfType([PropTypes.bool, PropTypes.func])
}

MultiSelectElement.defaultProps = {
  editingElement: {},
  noElementsSelected: false,
  isClearSelectionPresent: PropTypes.oneOfType([PropTypes.bool, PropTypes.func])
}

export { MultiSelectElement }
